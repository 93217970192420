









































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import { FundUserDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";

@Component({
  name: "FundUserList",
  components: {
    PagedTableView,
    AbSelect,
  },
})
export default class FundUserList extends Vue {
  queryForm = {
    userName: "",
    surname: "",
    fundName: "",
  };

  detailId = 0;

  // 获取表数据
  fetchData(params: any) {
    return api.fundUser.getAll(params);
  }

  // 新建
  handleCreate() {
    this.$router.push({ name: "fundUserCreate" });
  }

  // 查看详情
  jumpDetail(index: number, row: FundUserDto) {
    this.detailId = row.id!;
    this.$router.push({
      name: "fundUserDetail",
      params: { id: row.id!.toString() },
    });
  }

  // 编辑
  handleEdit(index: number, row: FundUserDto) {
    this.$router.push({
      name: "fundUserEdit",
      params: { id: row.id!.toString() },
    });
  }

  // 删除
  async handleDelete(index: number, row: FundUserDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.fundUser.delete({ id: row.id }).then((res) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    });
  }
}
